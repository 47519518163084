import { GetServerSideProps } from "next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import { useRouter } from "next/router"
import React from "react"

export const getServerSideProps: GetServerSideProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ["common", "admin/common"])),
  },
})

const SiteIndexPage: React.FunctionComponent = () => {
  const router = useRouter()

  router.replace("/orders")
  return null
}

export default SiteIndexPage
